<template>
  <a-config-provider :locale="zh_CN">
    <a-layout :style="{ minWidth: '900px' }">
      <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%' }">
        <div class="logo" />
        <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="horizontal" :style="{ lineHeight: '64px' }">
          <a-menu-item key="1"><router-link to="/">首页</router-link></a-menu-item>
          <a-menu-item key="2"><router-link to="/dragon">龙王</router-link></a-menu-item>
          <a-menu-item key="3"><router-link to="/danmu">弹幕</router-link></a-menu-item>
          <a-menu-item key="4"><router-link to="/user">用户</router-link></a-menu-item>
          <a-menu-item key="5"><router-link to="/gift">礼物</router-link></a-menu-item>
          <a-menu-item key="6"><router-link to="/activityList">活动</router-link></a-menu-item>
          <a-menu-item key="7"><router-link to="/data-graph">数据</router-link></a-menu-item>
          <a-menu-item key="8" v-if="switchHide"><router-link to="/login">登录</router-link></a-menu-item>
          <a-menu-item key="9" v-if="isLogin">
            <div @click="logout()">注销</div>
          </a-menu-item>
        </a-menu>
      </a-layout-header>
      <a-layout-content :style="{ padding: '0 50px', marginTop: '64px' }">
        <a-breadcrumb :style="{ margin: '16px 0' }">
          <!--<a-breadcrumb-item>Home</a-breadcrumb-item>
            <a-breadcrumb-item>List</a-breadcrumb-item>
            <a-breadcrumb-item>App</a-breadcrumb-item>-->
          <div></div>
        </a-breadcrumb>
        <div :style="{ background: '#fff', minHeight: '380px' }"><router-view /></div>
      </a-layout-content>
      <a-layout-footer :style="{ textAlign: 'center' }">
        ©2020 - now Project DMemory Powered by Vue.js and Ant Design
      </a-layout-footer>
    </a-layout>
  </a-config-provider>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { defineComponent, ref } from 'vue';
// import { defineComponent, ref } from 'vue';
import router from '@/router/index.js';
let selectedKeys = ref(['1']);
let switchHide = false;
let isLogin = false;
export default defineComponent({

  provide() { // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isReload: true,
      switchHide,
      selectedKeys,
      isLogin,
      zh_CN,
    };
  },
  methods: {
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    },
    logout() {
      // console.log("logout");
      window.localStorage.removeItem('Authorization');
      //router.push('/index');
      router.go('/index');
    }
  },
  watch: {
    $route(to, from) {
      // console.log(to, from);
      this.switchHide = window.localStorage.getItem('Authorization') === null;
      this.isLogin = window.localStorage.getItem('Authorization') !== null && window.localStorage.getItem('Authorization') !== '';
      // console.log(switchHide);
      // console.log(isLogin);
      switch (to.path) {
        case '/':
          this.selectedKeys = ref(['1']);
          break;
        case '/index':
          this.selectedKeys = ref(['1']);
          break;
        case '/dragon':
          this.selectedKeys = ref(['2']);
          break;
        case '/danmu':
          this.selectedKeys = ref(['3']);
          break;
        case '/user':
          this.selectedKeys = ref(['4']);
          break;
        case '/gift':
          this.selectedKeys = ref(['5']);
          break;
        case '/activityList':
          this.selectedKeys = ref(['6']);
          break;
        case '/activityDetail':
          this.selectedKeys = ref(['6']);
          break;
        case '/activityDetailSingle':
          this.selectedKeys = ref(['6']);
          break;
        case '/data-graph':
          this.selectedKeys = ref(['7']);
          break;
        case '/login':
          this.selectedKeys = ref(['8']);
          break;
      }
    }
  },

});
</script>
<style>
#components-layout-demo-fixed .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>

/*
 * @Author: ziyun.zhang@hand-china.com
 * @Date: 2023-01-13 10:50:04
 * @LastEditTime: 2023-03-07 09:36:46
 * @LastEditors: ziyun.zhang@hand-china.com
 * @Description: 
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue'
import axios from 'axios'
import VueAxios from "vue-axios"
import 'ant-design-vue/dist/antd.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { notification } from 'ant-design-vue';

// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
  config => {
    if (localStorage.getItem('Authorization')) {
      config.headers.Authorization = localStorage.getItem('Authorization');
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  });

// http response 拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code === 401) {
      notification["error"]({
        message: '请求被拒绝',
        description: '登录状态已失效，请重新登录',
      });
      localStorage.removeItem('Authorization');
      router.replace({
        path: '/login',
        query: { redirect: router.currentRoute.fullPath }
      })
    }
    return response;
  },
  error => {
    notification["error"]({
      message: '数据传输错误',
      description: '与机器狼传输数据时出现异常，请稍后再试',
    });
    return Promise.reject(error)   // 返回接口返回的错误信息
  });

createApp(App).use(Antd).use(ElementPlus).use(VueAxios, axios).use(router).mount('#app')

import { createRouter, createWebHashHistory } from 'vue-router'
import { notification } from 'ant-design-vue';

const prefix_title = ' - Project DMemory';

const routes = [
  {
    path: '/',
    name: 'Index',
    meta: {
      title: '首页' + prefix_title,
    },
    component: () => import('../views/Index.vue')
  },
  {
    path: '/index',
    name: 'Index1',
    meta: {
      title: '首页' + prefix_title,
    },
    component: () => import('../views/Index.vue')
  },
  {
    path: '/danmu',
    name: 'Danmu',
    meta: {
      title: '弹幕查询' + prefix_title,
    },
    component: () => import('../views/Danmu.vue')
  },
  {
    path: '/data-graph',
    name: 'DataGraph',
    meta: {
      title: '数据图表' + prefix_title,
    },
    component: () => import('../views/DataGraph.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于' + prefix_title,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/dragon',
    name: 'Dragon',
    meta: {
      title: '龙王查询' + prefix_title,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dragon.vue')
  },
  {
    path: '/user',
    name: 'User',
    meta: {
      title: '用户查询' + prefix_title,
    },
    component: () => import('../views/User.vue')
  },
  {
    path: '/gift',
    name: 'Gift',
    meta: {
      title: '礼物查询' + prefix_title,
    },
    component: () => import('../views/Gift.vue')
  },
  {
    path: '/activityList',
    name: 'activityList',
    meta: {
      title: '活动列表查询' + prefix_title,
    },
    component: () => import('../views/Activity.vue')
  },
  {
    path: '/activityDetail',
    name: 'activityDetail',
    meta: {
      title: '活动详情查询' + prefix_title,
    },
    component: () => import('../views/ActivityDetail.vue')
  },
  {
    path: '/activityDetailSingle',
    name: 'activityDetailSingle',
    meta: {
      title: '活动独立查询页' + prefix_title,
    },
    component: () => import('../views/ActivityDetailSingle.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录' + prefix_title,
    },
    component: () => import('../views/login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('Authorization');
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title
  }
  if (to.path === '/' || to.path === '/dragon' || to.path === '/activityDetailSingle') {
    next();
  } else if (to.path === '/login' && token != null && token != '') {
    notification["warning"]({
      message: '重复操作',
      description: '不需要再进行登录操作',
    });
    next('/index');
  } else {
    if (to.path != '/login' && token === null || token === '') {
      notification["warning"]({
        message: '无权访问',
        description: '请先登录再进行访问',
      });
      next({ path: '/login', query: { toPath: to.fullPath } });
    } else {
      next();
    }
  }

});

export default router
